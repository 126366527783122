import axios from "axios";
import { configAxiosApp } from "./config";

const clienteAxios = axios.create({
  baseURL: configAxiosApp.v01,
});

export const SupportAxios = axios.create({
  baseURL: "https://api.coopsys.com.do/support/1.0",
});

export const AdminAxios = axios.create({
  baseURL: "https://api.coopsys.com.do/core/1.0",
});

clienteAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

AdminAxios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const Authorization = localStorage.getItem("accessManagerToken");
if (Authorization) {
  AdminAxios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${Authorization}`;
}

export default clienteAxios;
