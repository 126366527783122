import axios from "axios";
import { configAxiosApp } from "./config";

const domain = window.location.hostname;


const Axios2 = axios.create({
  baseURL:  configAxiosApp.v02,
  headers: {
    domain : domain
  }
});




Axios2.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const token = localStorage.getItem("accessToken");
  
  // console.log(error.message)
  // if(error.message && error.message==="Network Error"){
  //   window.location = '/#/error/523';
  // }

  if (token && 511 === error.response.status) {
    window.location = '/#/error/511';
  }
  if (token && 401 === error.response.status) {

    console.log(error)

     localStorage.removeItem("accessToken");
     localStorage.removeItem("uname");
     localStorage.removeItem("refreshToken");
     localStorage.removeItem("UseData");
     window.location = '/';
    //  return Promise.reject();
  } else {
    return Promise.reject(error);
  }
});

export default Axios2;
